/* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');

body {
  margin: 0;  
  font-family: 'Merriweather', serif; 
  background-color: #121212;
  color: #ffffff;
}

a {
  color: #00d8ff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

* {
  box-sizing: border-box;
}
